
/* font */
html, body{
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Quicksand', sans-serif; */
  font-size: 16px;
}
.t-100{
  font-size: 100px;
  font-weight: 500;
}
.t-12{
  font-size: 12px;
}
.t-14{
  font-size: 14px;
}
.t-16{
  font-size: 16px;
}
.t-18{
  font-size: 18px;
}
.t-21{
  font-size: 21px;
}
.t-28{
  font-size: 28px;
}
.line-h-sm{
  line-height: 150%;
}
.line-h-md{
  line-height: 170%;
}
.t-light{
  font-weight: 200;
}
.t-reg{
  font-weight: 400;
}
.t-med{
  font-weight: 500;
}
.capitalize{
text-transform: uppercase;
}
a {
  text-decoration: none;
  transition: 0.4s all;
  color: black;
}
a:hover {
 /* text-decoration: none;*/
  opacity:0.5;
/*   border:none;
  outline: none;*/
}
a.webintext{
  padding-bottom: 3px;
  border-bottom: 1px solid black;
  /* line-height: 48px; */
}

/* layout */
.header{
  height:0px;
}
.h-1px{
  height:1px;
}
.flex {
display: flex;
}
.relative{
position:relative;
}
.absolute{
position:absolute;
}
.static{
  position: static;
}
.c-19 {
  width:19%;
}
.c-62 {
  width:62%
}
.c-100 {
  width:100%
}
.c-50 {
  width:50%
}
.centering-L50{
  left: 50%;
  transform: translate(-50%, 0%);
}
.centering-L10{
  left: 50%;
  transform: translate(-50%, 0%);
}
.centering{
  display: flex;
  align-items: center;
  justify-content: center;
}
.groups{
  width: 100px;
  height: 100px;
}

/* margin padding*/
.margin-bm-sm{
  margin-bottom: 20px;
}

.margin-right-md{
  margin-right: 40px;
}

.margin-top-sm{
  margin-top: 20px;
}

.padding-top-md{
  padding-top: 40px;
}

.padding-left-right-sm{
  padding-left: 20px;
  padding-right: 20px;
}

.padding-left-right-md{
  padding-left: 40px;
  padding-right: 40px;
}

.padding-left-right-lg{
  padding-left: 80px;
  padding-right: 80px;
}

.padding-sm{
  padding: 20px;
}
.padding-md{
  padding: 40px;
}
.padding-lg{
  padding: 40px;
}

/* boders */
.border-right{
  border-right: 1px solid black;
}
.border-left{
  border-left: 1px solid black;
}
.border-top{
  border-top: 1px solid black;
}
.border-bottom{
  border-bottom: 1px solid black;
}
.border-sm{
  border-bottom: 1px solid black;
  width: 30px;
  margin: auto;
}
.border-md{
  border-bottom: 1px solid black;
  width: 60px;
  margin: auto;
}
.border-lg{
  border-bottom: 1px solid black;
  width: 90px;
  margin: auto;
}


/* color */
.bg-black{
  background-color:black;
}
.txt-white{
  color:white;
}
.t-grey{
  color:rgb(141, 141, 141);
}
.t-black{
  color:black;
}

/* effects */
.displayTrue{
  display: none;
}
::-moz-selection { /* Code for Firefox */
  color: white;
  background: red;
}
::selection {
  color: white;
  background: red;
}
.rotate {
  position:relative;
  top: 0px;
  left: 93px;
  padding-top:10px;
  transform-origin: 0 0;
  transform: rotate(90deg);
}

/*space*/
.l-apple-box-multiple{
  height: 40px;
}
.l-apple-box-multiple-zero{
  height: 40px;
}
.l-apple-box{
  height: 20px;
}
.l-apple-box-half{
  height: 10px;
}
.l-apple-box-half-zero{
  height: 10px;
}
.l-apple-box-doublehalf{
  height: 5px;
}

.marquee {
  position: fixed;
  bottom: 0%;
  left: 0%;
  right: 0%;
  width: 100%;
  height: 50px;
  overflow: hidden;
  background-color: black;
  color: white;
}

.marquee-content {
  display: flex;
  list-style: none;
  animation: scrolling 20s linear infinite;
}

.marquee-item {
  /* height: 150px; */
  /* width: 100%; */
  margin-left: 10px;
  flex-shrink: 0;
}

.marquee-item .date{
font-weight: 600;
}

@keyframes scrolling {
  0% { transform: translateX(0); }
  100% { transform: translatex(-1000px); }
}

@media screen and (max-width:900px) {
  .c-19 {
    width:0%;
  }
  .c-62 {
    width:100%
  }

  .displayNone {
    display: none;
  }
  .displayTrue{
    display: block;
  }

  .t-100 {
    font-size: 50px;
  }
  .t-21 {
    font-size: 18px;
  }
  .t-18 {
    font-size: 14px;
  }
  .t-28{
    font-size: 18px;
  }
 

  .l-apple-box {
    height: 10px;
  }
  .l-apple-box-half {
    height: 5px;
  }
  .l-apple-box-doublehalf {
    height: 13px;
  }
  .l-apple-box-multiple{
    height: 30px;
  }
  .l-apple-box-multiple-zero{
    height: 17px;
  }
  .l-apple-box-half-zero{
    height: 0px;
  }
  .padding-left-right-md{
    padding-left: 0px;
    padding-right: 0px;
  }
}